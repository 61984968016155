<script setup lang="ts">
import { ref, computed, useTemplateRef } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { statusForTasks, getTaskColor } from '@util/tasks'
import useLocales from '@composable/useLocales'

import { FormKitIcon } from '@formkit/vue'
import LinkBuilder from '@component/LinkBuilder.vue'

export interface Props {
  status: string
  disabled?: boolean
  align?: 'left' | 'middle' | 'right'
  vAlign?: 'top' | 'bottom'
  menuClasses?: string
  loading?: boolean
}
export interface Emits {
  (e: 'update', value: string): void
}
const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { LL } = useLocales()

const taskEditEl = useTemplateRef<HTMLElement>('task-edit')
const showTaskStatus = ref(false)
const statusForData = computed<LinkData[]>(() => statusForTasks(LL, props.status))

onClickOutside(taskEditEl, () => {
  showTaskStatus.value = false
})

function updateStatus(newStatus: string) {
  emit('update', newStatus)
  showTaskStatus.value = false
}
</script>

<template>
  <div
    class="w-fit h-10 relative flex items-center justify-evenly py-2.5 px-3 rounded-lg"
    :class="`
      ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
      ${getTaskColor(status)}
    `"
    @click.stop="showTaskStatus = !showTaskStatus && !disabled"
  >
    <span class="whitespace-nowrap">
      {{ LL.taskModal.statusText[status as I18NTaskStatusText]() }}
    </span>

    <FormKitIcon v-if="loading" class="inline ml-2 rounded-md w-4 h-4 animate-spin" icon="spinner" />
    <FormKitIcon v-else class="inline ml-2 rounded-md w-4 h-4" icon="arrowDown" />

    <LinkBuilder
      v-if="showTaskStatus"
      ref="task-edit"
      :class="menuClasses"
      :align="align"
      :v-align="vAlign"
      :link-data="statusForData"
      @cancel="showTaskStatus = false"
      @accept="updateStatus"
      data-cy="task__board-task-item-edit-status-dropdown-list"
    />
  </div>
</template>
