import { defineStore } from 'pinia'
import { datasetSupportsAggregation } from '@util/gri'
import {
  fetchDataset,
  createDataset,
  updateDataset,
  deleteDataset,
  createOrganizationData,
  getAllDatasets,
} from '@composable/useApi'
import { updateUser } from '@api/user'
import type { AxiosError } from 'axios'
import { useUserStore, type User } from '@store/user'
import { useReportingEntitiesStore } from '@/stores/reportingEntities'
import { useSnackStore } from '@store/snackStore'
import { useGRIDataStore } from './gri'

export interface Dataset {
  url?: string
  id: string
  hierarchy_id?: string
  name: string
  description: string
  reporting_entity: string
  reporting_scope: string
  reporting_scope_id: string
  reporting_period: Period
  closed?: boolean
  closed_at?: string
}
interface DatePicker {
  isCustom: boolean
  date: {
    fromDate: string
    toDate: string
  }
}
export interface DatasetDetails extends Dataset {
  get_reporting_entity_name: string
}
type DatasetStoredData = StoredData<Dataset> & {
  allDatasets: DatasetDetails[]
  allDatasetsStaff: DatasetDetails[]
  aggregation: {
    datasetIds: string[]
  }
  datePicker: DatePicker
}

const thisYear = (() => {
  const y = new Date().getFullYear()

  return {
    begin: `${y}-01-01`,
    end: `${y}-12-31`,
  }
})()

export const useDatasetStore = defineStore('dataset', {
  state: (): DatasetStoredData => ({
    data: [],
    current: null,
    loading: true,
    ready: false,
    statusCode: 0,
    statusMessage: '',
    allDatasets: [],
    allDatasetsStaff: [],
    aggregation: {
      datasetIds: [],
    },
    pagination: {
      currentPage: 1,
      pageCount: 1,
    },
    datePicker: {
      isCustom: false,
      date: {
        fromDate: '',
        toDate: '',
      },
    },
  }),

  getters: {
    isOK(state) {
      return state.statusCode >= 200 && state.statusCode < 300
    },
    startDate(state) {
      if (!state.current) return thisYear.begin

      let startDate: string | null = null

      if (state.datePicker.isCustom) {
        startDate = state.datePicker.date.fromDate
        return startDate
      }

      state.allDatasets.forEach((dataset) => {
        if (state.aggregation.datasetIds.indexOf(dataset.id) === -1) return
        const newStartDate = dataset.reporting_period.start_date
        if (!startDate || newStartDate < startDate) startDate = newStartDate
      })
      return startDate || state.current.reporting_period.start_date
    },
    endDate(state) {
      if (!state.current) return thisYear.end

      let endDate: string | null = null

      if (state.datePicker.isCustom) {
        endDate = state.datePicker.date.toDate
        return endDate
      }

      state.allDatasets.forEach((dataset) => {
        if (state.aggregation.datasetIds.indexOf(dataset.id) === -1) return
        const newEndDate = dataset.reporting_period.end_date
        if (!endDate || newEndDate > endDate) endDate = newEndDate
      })
      return endDate || state.current.reporting_period.end_date
    },
    datasetsAsDropDownElements(state) {
      if (!state.data.length) return []
      const values: FormkitDropDownListElement[] = []
      state.data.forEach((dataset) =>
        values.push({
          label: dataset.name,
          value: dataset.id,
        }),
      )
      return values
    },
    isClosed(state): boolean {
      return !!state.current?.closed
    },
  },

  actions: {
    resetState() {
      this.data = []
      this.current = null
      this.loading = true
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''
    },
    resetVars() {
      this.loading = true
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''
    },
    async fetchData(ids: string[]) {
      const snackBar = useSnackStore()
      this.resetState()
      try {
        await this.fetchAllDatasetsAtOnce()
        const user = useUserStore()
        this.data = this.allDatasets.filter(({ id }) => ids.indexOf(id) !== -1)
        this.current = this.data.find((dataset) => dataset.id === user.datasetId) || this.data[0]
        if (this.current) {
          this.aggregation.datasetIds = datasetSupportsAggregation(this.current) ? [this.current.id] : []
        }
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
      } catch (error) {
        this.ready = false
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('errorFetchingDatasets')
      } finally {
        this.loading = false
      }
    },

    async fetchAllDatasets(page = 1, perPage = 10) {
      try {
        const paginatedResponse = await getAllDatasets(page, perPage)
        this.allDatasets = paginatedResponse.results
        this.pagination = {
          currentPage: page,
          pageCount: Math.ceil(paginatedResponse.count / perPage),
        }
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
      } catch (error) {
        this.ready = false
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        useSnackStore().error('errorFetchingDatasets')
      } finally {
        this.loading = false
      }
    },

    async fetchAllDatasetsAtOnce() {
      this.allDatasets = []
      let nextPage: number | null = 1
      try {
        while (nextPage !== null) {
          const paginatedResponse = await getAllDatasets(nextPage)
          nextPage = paginatedResponse.next ? nextPage + 1 : null
          this.allDatasets = [...this.allDatasets, ...paginatedResponse.results]
        }
        this.pagination = {
          currentPage: 1,
          pageCount: 1,
        }
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
      } catch (error) {
        this.ready = false
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        useSnackStore().error('errorFetchingDatasets')
      }
    },

    async fetchAllDatasetsForStaffAtOnce() {
      this.allDatasetsStaff = []
      let nextPage: number | null = 1
      try {
        while (nextPage !== null) {
          const paginatedResponse = await getAllDatasets(nextPage, 1000, true)
          nextPage = paginatedResponse.next ? nextPage + 1 : null
          this.allDatasetsStaff = [...this.allDatasetsStaff, ...paginatedResponse.results]
        }
        this.pagination = {
          currentPage: 1,
          pageCount: 1,
        }
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
      } catch (error) {
        this.ready = false
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        useSnackStore().error('errorFetchingDatasets')
      }
    },

    async createData(dataset: Dataset) {
      this.current = null
      const snackBar = useSnackStore()
      this.resetVars()
      try {
        const response: Dataset = await createDataset(dataset)
        const newDataset: Dataset = await fetchDataset(response.id)
        if (newDataset.reporting_scope_id === 'esg') {
          const entityStore = useReportingEntitiesStore()
          //todo: improve create organization data later
          if (entityStore.current) await createOrganizationData(newDataset.id, entityStore.current.name)
        }
        this.data.push(newDataset)
        await this.updateCurrentDataset(response.id)
        this.loading = false
        this.statusCode = 201
        this.statusMessage = 'OK'
        this.ready = true
        snackBar.success('dataSetCreated')
      } catch (error) {
        this.ready = false
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('dataSetNotCreated')
      } finally {
        this.loading = false
      }
    },

    async updateData(dataset: Dataset) {
      this.resetVars()
      const snackBar = useSnackStore()
      try {
        const response: Dataset = await updateDataset(dataset)
        this.data = this.data.map((ds) => (ds.id === response.id ? response : ds))
        if (this.current && dataset.id === this.current.id) this.updateCurrentDataset(dataset.id)
        this.loading = false
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
        snackBar.success('dataSetUpdated')
      } catch (error) {
        this.ready = false
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('dataSetNotUpdated')
      } finally {
        this.loading = false
      }
    },

    async deleteDatasetForUser(id: string) {
      this.resetVars()
      const snackBar = useSnackStore()
      try {
        await deleteDataset(id)
        this.data = this.data.filter((dataset) => dataset.id !== id)
        this.allDatasets = this.allDatasets.filter((dataset) => dataset.id !== id)
        if (this.current && id === this.current.id) this.updateCurrentDataset(this.data[0].id)
        this.loading = false
        this.statusCode = 204
        this.statusMessage = 'OK'
        this.ready = true
        snackBar.success('dataSetDeleted')
      } catch (error) {
        this.ready = false
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.success('deleteDatasetErrorMsg')
      } finally {
        this.loading = false
      }
    },

    async toggleDatasetLock(dataset: Dataset) {
      const snackBar = useSnackStore()
      try {
        const updatedDataset: Dataset = await updateDataset({
          id: dataset.id,
          closed: !dataset.closed,
        })
        dataset.closed = updatedDataset.closed
        if (this.current?.id === dataset.id) {
          this.current.closed = dataset.closed
        }
        snackBar.success('dataSetUpdated')
      } catch (error) {
        snackBar.error('dataSetNotUpdated')
      }
    },

    async getReportingScopes() {
      try {
        this.loading = false
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
      } catch (error) {
        this.ready = false
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
      } finally {
        this.loading = false
      }
    },

    async updateCurrentDataset(id: string) {
      const user = useUserStore()
      const privateSettings = user.user.private_settings
      privateSettings.dataset = id

      const UpdatedUser: User = await updateUser(user.user.id, { private_settings: privateSettings })
      await user.setUser(UpdatedUser)
      this.current = this.data.find((dataset) => dataset.id === id) || null
      //resetting the gri store so that all the data gets loaded again
      const griStore = useGRIDataStore()
      griStore.$reset()
    },
  },
})
