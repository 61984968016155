<script setup lang="ts">
import { computed } from 'vue'
import { FormKitIcon } from '@formkit/vue'

export interface Props {
  text: string
  type?: 'info' | 'warning' | 'error'
  big?: boolean
}
withDefaults(defineProps<Props>(), { type: 'info',big: false, })

const size = computed(() => (__props.big ? 6 : 5))
const typeClass = computed(() => {
  switch (__props.type) {
    case 'warning':
      return 'text-yellow bg-yellow/10 border-yellow/10'
    case 'error':
      return 'text-red bg-red/10 border-red/10'
    default:
      return 'text-primary bg-primary/10 border-primary/10'
  }
})
</script>
<template>
  <div
    class="flex items-center gap-2 p-3 rounded-lg border shadow-sm"
    :class="[typeClass, big ? 'text-sm' : 'text-xs']"
  >
    <FormKitIcon icon="infoIcon" :class="[type === 'info' ? 'rotate-180' : '', `w-${size}`]" />
    <p class="w-11/12">{{ text }}</p>
  </div>
</template>
