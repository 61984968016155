import { defineStore } from 'pinia'
import type { AxiosError } from 'axios'
import type { User } from '@store/user'
import {
  getUserProfile,
  getUserProfiles,
  inviteUser,
  updateUserDetails,
  deleteUser,
  resendUserActivation,
} from '@api/user'
import { useSnackStore } from '@store/snackStore'

export const useUserManagementStore = defineStore('userManagement', {
  state: (): StoredData<User> => ({
    data: [],
    current: null,
    loading: true,
    ready: false,
    statusCode: 0,
    statusMessage: '',
    pagination: {
      currentPage: 1,
      pageCount: 1,
    },
  }),

  getters: {
    isOK(state) {
      return state.statusCode >= 200 && state.statusCode < 300
    },
  },

  actions: {
    resetState() {
      this.data = []
      this.current = null
      this.loading = true
      this.ready = false
      this.statusCode = 0
      this.statusMessage = ''
    },
    async fetchUsers(page = 1, perPage = 10) {
      const snackBar = useSnackStore()
      this.resetState()
      try {
        const paginatedResponse = await getUserProfiles(page)
        this.data = paginatedResponse.results
        this.pagination = {
          currentPage: page,
          pageCount: Math.ceil(paginatedResponse.count / perPage),
        }
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('errorGettingAllUsers')
      } finally {
        this.loading = false
      }
    },

    async addUser(user: Partial<User>) {
      const snackBar = useSnackStore()
      try {
        await inviteUser(user)
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
        snackBar.success('invitationLinkSentTo')
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        if (this.statusCode === 400) snackBar.error('userAlreadyExists', Infinity)
      } finally {
        this.loading = false
      }
    },

    async updateUser(user: Partial<User>, email: string) {
      const snackBar = useSnackStore()
      try {
        const userIdToUpdate = this.data.find((dat) => dat.email === email)?.id
        if (userIdToUpdate) {
          const updatedUser = await updateUserDetails(user, userIdToUpdate)
          snackBar.success('userDetailsUpdated')
          const index = this.data.findIndex((user) => user.email === email)
          this.data[index] = updatedUser
        } else {
          snackBar.warning('userIdDoesNotExist')
        }
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('userDetailsNotUpdated')
      } finally {
        this.loading = false
      }
    },
    async deleteUser(id: string) {
      const snackBar = useSnackStore()
      try {
        await deleteUser(id)
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
        snackBar.success('userDeleted')
        this.data = this.data.filter((user) => user.id !== id)
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.error('userNotDeleted')
      } finally {
        this.loading = false
      }
    },

    async resendActivation(email: string) {
      const snackBar = useSnackStore()
      try {
        await resendUserActivation(email)
        this.statusCode = 200
        this.statusMessage = 'OK'
        this.ready = true
        snackBar.success('activationLinkResent')
      } catch (error) {
        this.statusCode = (error as AxiosError).response?.status || 500
        this.statusMessage = (error as AxiosError).response?.statusText || ''
        snackBar.success('activationLinkNotSent')
      } finally {
        this.loading = false
      }
    },

    async fetchUser(id: string) {
      const user = this.data.find((user) => user.id === id)
      if (!user) {
        const response = await getUserProfile(id)
        this.data.push(response)
        return response
      }
      return user
    },
  },
})
