<script setup lang="ts">const outerClass = __MACROS_toRef(__props, "class");

import { toRef as __MACROS_toRef } from "vue";
/**
 * AppButton Component
 * Main button to use for the application. Is by default a primary button but can be made secondary or tertiary by setting the respective attribute:
 */
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import useLocales from '@composable/useLocales'

export interface Props {
  /** The label to show on the button, defaults to 'click me' */
  label?: string
  /** optional help text */
  help?: string
  /** a list of errors */
  errors?: string[]
  /** make secondary button, defaults to false */
  secondary?: boolean
  /** make tertiary button, defaults to false */
  tertiary?: boolean
  /** make tab button, defaults to false and used in headers */
  tabBtn?: boolean
  /** make nav button, */
  navBtn?: boolean
  /** make inactive nav button, */
  inactiveNavBtn?: boolean
  /** make a submit button for forms, defaults to false */
  submit?: boolean
  /** button is disabled, defaults to false */
  disabled?: boolean
  /** show an icon left of the button label */
  icon?: string
  /** additional css classes, added to outerClass */
  class?: string
  /** highlight button (looks like it is being hovered) */
  highlighted?: boolean
  /** encase the button with <router-link :to="asLinkTo"> */
  asLinkTo?: RouteLocationRaw
  asExternalLinkTo?: string
  /** danger button, defaults to false
   */
  danger?: boolean
  /** when nav button is used for back navigation */
  backBtn?: boolean
}

withDefaults(defineProps<Props>(), { label: '',help: '',errors: () => ([]),secondary: false,tertiary: false,tabBtn: false,navBtn: false,inactiveNavBtn: false,submit: false,disabled: false,highlighted: false,danger: false, })

const { LL } = useLocales()

const primary = computed(() => !__props.secondary && !__props.tertiary && !__props.tabBtn && !__props.navBtn && !__props.inactiveNavBtn)
const buttonType = computed(() => (__props.submit ? 'submit' : 'button'))
const computedLabel = computed(() => {
  if (!__props.label) return __props.submit ? LL.value.submit() : '...'
  return __props.label
})
</script>

<template>
  <RouterLink :to="asLinkTo" v-if="asLinkTo">
    <AppButton v-bind="{ ...$props, asLinkTo: undefined }" />
  </RouterLink>

  <a :href="asExternalLinkTo" v-else-if="asExternalLinkTo" target="_blank">
    <AppButton v-bind="{ ...$props, asExternalLinkTo: undefined }" />
  </a>

  <FormKit
    v-else
    :type="buttonType"
    :wrapper-class="{
      'border-0 rounded-lg transition-colors duration-300': true,
      'bg-red-error text-white': danger,
      'text-black dark:text-white bg-white dark:bg-black hover:bg-white-soft hover:text-primary':
        !primary && !submit && !tabBtn && !navBtn && !inactiveNavBtn,
      'bg-primary text-white border-primary': primary || submit,
      'border-2 border-gray hover:border-gray-light dark:border-primary dark:hover:border-gray-light dark:bg-black-soft':
        secondary,
      'border-white dark:border-black underline text-primary hover:border-gray dark:hover:border-gray': tertiary,
      'bg-gray-btn dark:bg-black-soft border-white dark:border-black hover:border-gray dark:hover:border-gray': tabBtn,
      'bg-blue-soft-btn dark:bg-primary text-blue dark:text-white dark:hover:border-gray': navBtn,
      'bg-white dark:bg-black-soft text-black dark:text-white-soft dark:hover:border-gray hover:bg-primary hover:text-white':
        inactiveNavBtn,
      'opacity-50': disabled,
      'bg-white-soft text-primary': highlighted,
    }"
    input-class="w-full px-4 py-2 flex justify-center items-center text-center text-base font-medium focus:outline-none disabled:cursor-not-allowed"
    prefix-icon-class="w-6 mr-2"
    message-class="text-error"
    help-class="text-gray"
    :label="computedLabel"
    v-bind="{ help, errors, disabled, outerClass }"
    :prefix-icon="icon"
  />
</template>
