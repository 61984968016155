import type { ReportingRequirement } from '@/stores/reporting/requirements'
import { createObjectFactory, fetchSingleFactory, deleteObjectFactory, updateObjectFactory } from '@util/api'
import { apiFetch, apiCreate, apiUpdate } from '@api/client'
import { P } from 'ts-pattern'

export async function fetchReportingRequirements({
  reportId,
  showUnpublished,
  standardId,
  searchQuery,
}: {
  reportId: string
  showUnpublished: boolean
  standardId?: number | string
  searchQuery?: string
}): Promise<ReportingRequirement[]> {
  const params: Record<string, any> = {
    show_unpublished: showUnpublished,
  }
  if (standardId) params.reporting_standard_id = standardId
  if (searchQuery) params.title__icontains = searchQuery
  const url = `reports/${reportId}/reporting-requirements`
  const response = await apiFetch<{ results: ReportingRequirement[] }>(url, params)
  return response.results
}

export async function updateReportingRequirement(
  reportId: string,
  requirementId: number,
  disclosure: any,
): Promise<ReportingRequirement> {
  const url = `reports/${reportId}/reporting-requirements/${requirementId}`
  return await apiUpdate<ReportingRequirement>(url, { disclosure })
}

export async function unassignReportingRequirement(reportId: string, ...requirementIds: number[]): Promise<void> {
  const url = `reports/${reportId}/unassign`
  await apiCreate<any>(url, {
    reporting_requirements: requirementIds,
  })
}

export async function getAssignedUsers(reportId: string) {
  const url = `reports/${reportId}/reporting-requirements/assigned`
  return await apiFetch(url)
}

export async function assignReportingRequirement(
  reportId: string,
  user: string,
  external = false,
  ...reportingRequirements: number[]
) {
  const url = `reports/${reportId}/assign`
  const payload: { reporting_requirements: number[]; email?: string; user_id?: string } = {
    reporting_requirements: reportingRequirements,
  }
  external ? (payload['email'] = user) : (payload['user_id'] = user)

  return (await apiCreate(url, payload)) as { assignee: string }
}

const assignmentPattern = P.union({
  assignee: P.union(P.nullish, P.string),
  assignee_email: P.optional(P.string),
  assigning_user_email: P.union(P.nullish, P.string),
  reviewer: P.union(P.nullish, P.string),
})
export type AssignmentDTO = P.infer<typeof assignmentPattern>

type AssignmentURLParams = { reportId: UUID; requirementId: number }

export const fetchAssignment = fetchSingleFactory<AssignmentDTO, Record<string, any>, AssignmentURLParams>(
  '/reports/:reportId:/reporting-requirements/:requirementId:/assignment',
  assignmentPattern,
)
export const createAssignment = createObjectFactory<AssignmentDTO, Partial<AssignmentDTO>, AssignmentURLParams>(
  '/reports/:reportId:/reporting-requirements/:requirementId:/assignment',
  assignmentPattern,
)
export const updateAssignment = updateObjectFactory<AssignmentDTO, Partial<AssignmentDTO>, AssignmentURLParams>(
  '/reports/:reportId:/reporting-requirements/:requirementId:/assignment',
  assignmentPattern,
)
export const deleteAssignment = deleteObjectFactory<void, AssignmentURLParams>(
  '/reports/:reportId:/reporting-requirements/:requirementId:/assignment',
)
