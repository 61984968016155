import { defineStore } from 'pinia'
import { refreshAccess, tokenStore, login, logout, getProfile, updateUserDetails } from '@api/user'
import { useReportingEntitiesStore } from '@store/reportingEntities'
import { isNonAuthRoute, isExternalRoute } from '@/util/routes'
import { ref, computed } from 'vue'

export interface UserPermissions {
  id: string
  created_at: string
  modified_at: string
  string: string
  description: string
}

export interface User {
  id: string
  email: string
  email_verified: boolean
  first_name: string
  last_name: string
  date_joined: string
  hierarchy_id: string
  is_active: boolean
  is_reporting_entity_admin: boolean
  is_staff: boolean
  is_external: boolean
  is_onboarded: boolean
  position: string
  private_settings: UserSettings
  reporting_entity: string
  get_reporting_entity_name: string
  permissions: UserPermissions[]
}

const DEFAULT_USER: User = {
  id: '',
  email: '',
  email_verified: false,
  first_name: '',
  last_name: '',
  date_joined: '',
  hierarchy_id: '',
  is_active: false,
  is_reporting_entity_admin: false,
  is_staff: false,
  is_external: false,
  is_onboarded: false,
  position: '',
  private_settings: {
    dataset: '',
    language: 'de',
    onboarding: { materiality: false },
  },
  reporting_entity: '',
  get_reporting_entity_name: '',
  permissions: [],
}

export const useUserStore = defineStore('user', () => {
  const user = ref(DEFAULT_USER)
  const loggedIn = ref(false)
  const ready = ref(false)
  const isLoading = ref(true)
  const showOnboarding = ref(false)

  const { fetchData: fetchReportingEntity, resetState: resetReportingEntities } = useReportingEntitiesStore()

  async function setUser(newUser: User) {
    loggedIn.value = true

    const defaultReportingEntity = localStorage.getItem('default_reporting_entity')
    let reportingEntity = localStorage.getItem('reporting_entity')

    if (defaultReportingEntity !== newUser.reporting_entity) {
      localStorage.setItem('default_reporting_entity', newUser.reporting_entity)
      localStorage.setItem('reporting_entity', newUser.reporting_entity)
      reportingEntity = newUser.reporting_entity
    }

    if (!reportingEntity) {
      reportingEntity = newUser.reporting_entity
      localStorage.setItem('reporting_entity', reportingEntity)
    }

    user.value = newUser

    await fetchReportingEntity(reportingEntity)
    const { current: currentEntity } = useReportingEntitiesStore()

    if (!user.value.private_settings && currentEntity && currentEntity.datasets && currentEntity.datasets.length > 0) {
      user.value.private_settings = { dataset: currentEntity.datasets[0], onboarding: { materiality: false } }
    } else if (!user.value.private_settings) {
      user.value.private_settings = { dataset: '', onboarding: { materiality: false } }
    }
    ready.value = true
  }

  function resetUser() {
    loggedIn.value = false
    ready.value = false
    resetReportingEntities()

    user.value = DEFAULT_USER
  }

  const { email } = tokenStore.value
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const originPathname = window.location.pathname

  if (email) {
    if (import.meta.env.VITE_ENVIRONMENT !== 'production') console.debug('user already logged in as', email)
    user.value.email = email
    loggedIn.value = true
    refreshAccessToken().then(() => (isLoading.value = false))
  } else if (token) {
    handleTokenLogin(token).then(() => (isLoading.value = false))
  } else {
    console.debug('user not logged in')
    isLoading.value = false
    handleLogout()
  }

  const nameOrEmail = computed(() => {
    const firstName = user.value.first_name
    const lastName = user.value.last_name

    return firstName && lastName ? `${firstName} ${lastName}` : user.value.email
  })

  const initials = computed(() => {
    if (user.value.is_external) return 'XX'
    const first = user.value.first_name[0] || ''
    const second = user.value.last_name[0] || ''

    return `${first}${second}`.toUpperCase()
  })

  const datasetId = computed(() => {
    const { current: currentEntity } = useReportingEntitiesStore()
    if (
      user.value.private_settings &&
      user.value.private_settings.dataset &&
      currentEntity?.datasets.includes(user.value.private_settings.dataset)
    ) {
      return user.value.private_settings?.dataset
    } else {
      return currentEntity?.datasets[0] as string
    }
  })

  const isAdmin = computed(() => user.value.is_staff || user.value.is_reporting_entity_admin)
  const isStaff = computed(() => user.value.is_staff)
  const isAmplitudeEnabled = computed(
    () =>
      // by default amplitude analytics should be enabled.
      // but right now keeping it disabled to be GDPR compliant.
      user.value.private_settings?.analytics?.amplitude ?? false,
  )

  function handleLogout() {
    resetUser()
    logout()

    const nonAuthRoute = isNonAuthRoute(window.location.pathname as string)
    const externalRoute = isExternalRoute(window.location.pathname as string)

    if (!nonAuthRoute && !externalRoute)
      window.location.replace(`/login?origin=${window.location.pathname}${window.location.search}`)
  }

  async function refreshAccessToken() {
    try {
      await refreshAccess()
      const profile = await getProfile()
      tokenStore.value.email = tokenStore.value.email ?? profile.email
      await setUser(profile)
    } catch (err) {
      console.debug('failed refresh access token, logging out', err)
      handleLogout()
    }
  }

  async function handleTokenLogin(token: string) {
    try {
      const profile = await login(token)
      user.value.email = profile.email
      loggedIn.value = true
      const { access, exp } = tokenStore.value
      tokenStore.value = { access, email: profile.email, exp }
      await setUser(profile)

      const assigned = urlParams.get('assigned')
      const url = new URL(`${window.location.origin}${originPathname}`)

      if (assigned) url.searchParams.set('assigned', assigned as string)
      return window.location.replace(url.toString())
    } catch (err) {
      console.debug('failed logging external user, logging out', err)
      handleLogout()
    }
  }

  async function finishOnboarding() {
    if (user.value.is_onboarded) {
      toggleOnboardingModal()
      return
    }
    await updateUserDetails({ is_onboarded: true }, user.value.id)
    user.value.is_onboarded = true
  }

  function toggleOnboardingModal() {
    showOnboarding.value = !showOnboarding.value
  }
  const forceMaterialityOnboarding = ref(false)

  const showMaterialityOnboarding = computed(() => {
    return !user.value.private_settings.onboarding?.materiality || forceMaterialityOnboarding.value
  })

  async function finishMaterialityOnboarding() {
    if (user.value.private_settings.onboarding?.materiality) {
      toggleMaterialityOnboarding()
      return
    }
    user.value.private_settings.onboarding = { materiality: true }
    await updateUserDetails({ private_settings: user.value.private_settings }, user.value.id)
  }

  async function updateAnalyticsSetting() {
    const settings = user.value.private_settings
    settings.analytics = { amplitude: !isAmplitudeEnabled.value }
    await updateUserDetails({ private_settings: settings }, user.value.id)
  }

  function toggleMaterialityOnboarding() {
    forceMaterialityOnboarding.value = !forceMaterialityOnboarding.value
  }

  return {
    user,
    nameOrEmail,
    initials,
    datasetId,
    isAdmin,
    isStaff,
    isAmplitudeEnabled,
    loggedIn,
    setUser,
    resetUser,
    ready,
    isLoading,
    finishOnboarding,
    showOnboarding,
    toggleOnboardingModal,
    showMaterialityOnboarding,
    finishMaterialityOnboarding,
    toggleMaterialityOnboarding,
    updateAnalyticsSetting,
  }
})
