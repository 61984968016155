import { isMatching, P } from 'ts-pattern'
import { apiFetch, apiCreate, apiUpdate, apiDelete } from '@api/client'
import { success, failure, paginatedPattern } from '@util/types'
import { AxiosError } from 'axios'

/**
 * Replaces dynamic URL parameters with given values
 */
function renderUrl(urlTemplate: string, urlParams: Record<string, any>) {
  return urlTemplate.replace(/:([A-Za-z0-9_-]+):/g, (match, key) => {
    return key in urlParams ? String(urlParams[key]) : match
  })
}

/**
 * Custom error class for handling validation failures
 *
 * @extends Error
 *
 * @remarks
 * Used when data validation fails, for example when API responses don't match
 * expected patterns or schemas. Stores the invalid data for debugging purposes.
 *
 * @property {string} message - The error message
 * @property {string} name - Always set to "ValidationError"
 * @property {any} validatedObject - The object that failed validation
 *
 * @example
 * ```ts
 * try {
 *   const data = validateApiResponse(response)
 * } catch (error) {
 *   if (error instanceof ValidationError) {
 *     console.error('Validation failed:', error.message)
 *     console.error('Invalid data:', error.validatedObject)
 *   }
 * }
 * ```
 */
export class ValidationError extends Error {
  constructor(
    message: string,
    public validatedObject: any,
  ) {
    super(message)
    this.name = 'ValidationError'
  }
}

/**
 * Custom error class for handling erraneous parameters
 *
 * @extends Error
 *
 * @remarks
 * Used when given parameters are not sufficient or of the wrong type
 *
 * @property {string} message - The error message
 * @property {string} name - Always set to "ParameterError"
 *
 * @example
 * ```ts
 * const createObj = createObjectFactory()
 * createObj() // => failure(new ParameterError("Either URL or dynamicUrl is required"))
 * ```
 */
export class ParameterError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ParameterError'
  }
}

/**
 * Creates a factory function for fetching single objects from an API endpoint
 *
 * @template T - The type of object being fetched
 * @template Params - The type for optional query parameters, must be a record of key-value pairs
 * @template UrlParams - Type for URL parameters, must extend Record<string, any>
 *
 * @param url - The base API endpoint URL for the object type - !without object-id!
 * @param expectedPattern - Pattern to validate the API response against
 *
 * @returns An async function that accepts an object ID and optional query parameters,
 * returning a Result containing either the requested object or an error
 *
 * @remarks
 * - Uses apiFetch() internally to make the GET request
 * - Supports dynamic URL parameters using :param: syntax (eg /foo/:param:/bar)
 * - Validates the response against expectedPattern
 * - Returns a Result type that represents either success or failure
 * - On validation failure, logs error and returns ValidationError with invalid data
 * - On network/API failure, returns the AxiosError
 *
 * @example
 * ```ts
 * interface User {
 *   id: string
 *   name: string
 *   profile: string
 * }
 *
 * interface UserParams {
 *   include_details?: boolean
 *   fields?: string[]
 * }
 *
 * const fetchUser = fetchSingleFactory<User, UserParams>('/api/users/:userId', userPattern)
 * const result = await fetchUser(
 *   { include_details: true },
 *   { userId: '123e4567-e89b-12d3-a456-426614174000' },
 * )
 *
 * if (result.success) console.log(result.value) // User object
 * else console.error(result.error) // AxiosError or ValidationError
 * ```
 */
export function fetchSingleFactory<
  T,
  Params extends Record<string, any> = Record<string, any>,
  UrlParams extends Record<string, any> = Record<string, any>,
>(urlTemplate: string, expectedPattern: any) {
  const isPattern = isMatching(expectedPattern)

  return async (params?: Params, urlParams?: UrlParams): Promise<Result<T>> => {
    const url = urlParams ? renderUrl(urlTemplate, urlParams) : urlTemplate
    try {
      const dto = await apiFetch<T>(url, params)
      if (isPattern(dto)) return success(dto)
      else {
        console.error(url, 'returned unexpected format', { returned: dto })
        return failure(new ValidationError('Unexpected API response', dto))
      }
    } catch (err) {
      return failure(err as AxiosError)
    }
  }
}

/**
 * Creates a factory function for fetching paginated lists of objects from an API endpoint
 *
 * @template T - The type of objects being fetched
 * @template Args - The query parameters type, must be a record of key-value pairs
 * @template UrlParams - Type for URL parameters, must extend Record<string, any>
 *
 * @param url - The API endpoint URL to fetch from
 * @param expectedPattern - Pattern to validate the API response against
 *
 * @returns An async function that accepts query parameters and optional URL parameters,
 * returning a Result containing either a paginated list of T objects or an error
 *
 * @remarks
 * - Uses apiFetch() internally to make the GET request
 * - Supports dynamic URL parameters using :param: syntax (eg /foo/:param:/bar)
 * - Automatically adds pagination parameters (page=1, page_size=100) if not provided
 * - Validates response against expectedPattern wrapped in a pagination structure
 * - Returns a Result type that represents either success or failure
 * - On validation failure, logs error and returns ValidationError with invalid data
 * - On network/API failure, returns the AxiosError
 *
 * @example
 * ```ts
 * interface User {
 *   id: number
 *   name: string
 * }
 *
 * interface UserFilters {
 *   search?: string
 *   role?: string
 * }
 *
 * const fetchUsers = fetchPaginatedFactory<User, UserFilters>('/api/users', userPattern)
 * const result = await fetchUsers({ role: 'admin' })
 *
 * if (result.success) {
 *   console.log(result.value.results) // Array of User objects
 *   console.log(result.value.count) // Total count
 * } else {
 *   console.error(result.error) // AxiosError or ValidationError
 * }
 * ```
 */
export function fetchPaginatedFactory<
  T,
  Params extends Record<string, any> = Record<string, any>,
  UrlParams extends Record<string, any> = Record<string, any>,
>(urlTemplate: string, expectedPattern: any) {
  const isPattern = isMatching(paginatedPattern(expectedPattern))
  return async (params: Params, urlParams?: UrlParams): Promise<Result<Paginated<T>, AxiosError | ValidationError>> => {
    const url = urlParams ? renderUrl(urlTemplate, urlParams) : urlTemplate
    params = { page: 1, page_size: 100, ...params }

    try {
      const dto = await apiFetch<Paginated<T>>(url, params)
      if (isPattern(dto)) return success(dto)
      else {
        console.error(url, 'returned unexpected format', { returned: dto })
        return failure(new ValidationError('Unexpected API response', dto))
      }
    } catch (err) {
      return failure(err as AxiosError)
    }
  }
}

/**
 * Creates a factory function for making API POST requests to create objects of type T
 *
 * @template T - The expected return type from the API
 * @template U - The input object type, defaults to Partial<T>
 * @template UrlParams - Type for URL parameters, must extend Record<string, any>
 *
 * @param url - The API endpoint URL to send the POST request to
 * @param expectedPattern - Optional pattern to validate the API response against
 *
 * @returns An async function that takes an object of type U and optional URL
 * parameters, returning a Result containing either the successfully created object of
 * type T or an AxiosError
 *
 * @remarks
 * - Uses apiCreate() internally to make the POST request
 * - Supports dynamic URL parameters using :param: syntax (eg /foo/:param:/bar)
 * - Validates the response against expectedPattern if provided
 * - Returns a Result type that represents either success or failure
 * - On validation failure, logs error and returns ValidationError with invalid data
 * - On network/API failure, returns the AxiosError
 *
 * @example
 * ```ts
 * interface User {
 *   id: number
 *   name: string
 * }
 *
 * const createUser = createObjectFactory<User>('/api/:companyId:/users')
 * const result = await createUser({ name: 'John' }, { companyId: 123 })
 *
 * if (result.success) console.log(result.value) // User object
 * else console.error(result.error) // AxiosError
 * ```
 */
export function createObjectFactory<T, U = Partial<T>, UrlParams extends Record<string, any> = Record<string, any>>(
  urlTemplate: string,
  expectedPattern: any = P.any,
) {
  const isPattern = isMatching(expectedPattern)

  return async (object: U, urlParams?: UrlParams): Promise<Result<T>> => {
    const url = urlParams ? renderUrl(urlTemplate, urlParams) : urlTemplate
    try {
      const dto = await apiCreate<T>(url, object)
      if (isPattern(dto)) return success(dto)
      else {
        console.error(url, 'returned unexpected format', { returned: dto })
        return failure(new ValidationError('Unexpected API response', dto))
      }
    } catch (err) {
      return failure(err as AxiosError)
    }
  }
}

/**
 * Creates a factory function for updating objects via API PATCH requests
 *
 * @template T - The type of object being updated
 * @template U - The type of the update data, defaults to Partial<T>
 * @template UrlParams - Type for URL parameters, must extend Record<string, any>
 *
 * @param url - The base API endpoint URL for the object type - !without object-id!
 * @param expectedPattern - Pattern to validate the API response against
 *
 * @returns An async function that optionally accepts url parameters and update data,
 * returning a either the updated object or an error
 *
 * @remarks
 * - Uses apiUpdate() internally to make the PATCH request
 * - Supports dynamic URL parameters using :param: syntax (eg /foo/:param:/bar)
 * - Validates the response against expectedPattern
 * - Returns a Result type that represents either success or failure
 * - On validation failure, logs error and returns ValidationError with invalid data
 * - On network/API failure, returns the AxiosError
 *
 * @example
 * ```ts
 * interface User {
 *   id: string
 *   name: string
 *   email: string
 * }
 *
 * interface UserUpdate {
 *   name?: string
 *   email?: string
 * }
 *
 * const updateUser = updateObjectFactory<User, UserUpdate>('/api/users', userPattern)
 * const result = await updateUser(
 *   '123e4567-e89b-12d3-a456-426614174000',
 *   { name: 'New Name' }
 * )
 *
 * if (result.success) console.log(result.value) // Updated User object
 * else console.error(result.error) // AxiosError or ValidationError
 * ```
 */
export function updateObjectFactory<T, U = Partial<T>, UrlParams extends Record<string, any> = Record<string, any>>(
  urlTemplate: string,
  expectedPattern: any,
) {
  const isPattern = isMatching(expectedPattern)

  return async (objectUpdate: U, urlParams?: UrlParams): Promise<Result<T>> => {
    const url = urlParams ? renderUrl(urlTemplate, urlParams) : urlTemplate
    try {
      const dto = await apiUpdate<T>(url, objectUpdate)
      if (isPattern(dto)) return success(dto)
      else {
        console.error(url, 'returned unexpected format', { returned: dto })
        return failure(new ValidationError('Unexpected API response', dto))
      }
    } catch (err) {
      return failure(err as AxiosError)
    }
  }
}

/**
 * Creates a factory function for deleting objects via API DELETE requests
 *
 * @template T - The expected return type from the API, defaults to void
 * @template UrlParams - Type for URL parameters, must extend Record<string, any>
 *
 * @param url - The base API endpoint URL for the object type - !without object-id!
 * @param expectedPattern - Optional pattern to validate the API response against
 *
 * @returns An async function that optionally accepts url parameters and returns a
 * Result containing either the API response or an error
 *
 * @remarks
 * - Uses apiDelete() internally to make the DELETE request
 * - Supports dynamic URL parameters using :param: syntax (eg /foo/:param:/bar)
 * - Validates the response against expectedPattern if provided
 * - Returns a Result type that represents either success or failure
 * - On validation failure, logs error and returns ValidationError with invalid data
 * - On network/API failure, returns the AxiosError
 *
 * @example
 * ```ts
 * // Simple delete with no response
 * const deleteUser = deleteObjectFactory('/api/users')
 * const result = await deleteUser('123e4567-e89b-12d3-a456-426614174000')
 *
 * // Delete with typed response
 * interface DeleteResponse {
 *   message: string
 *   deletedAt: string
 * }
 *
 * const deleteUserWithResponse = deleteObjectFactory<DeleteResponse>(
 *   '/api/users',
 *   deleteResponsePattern
 * )
 * const result = await deleteUserWithResponse('123e4567-e89b-12d3-a456-426614174000')
 *
 * if (result.success) console.log(result.value) // DeleteResponse or void
 * else console.error(result.error) // AxiosError or ValidationError
 * ```
 */
export function deleteObjectFactory<T = void, UrlParams extends Record<string, any> = Record<string, any>>(
  urlTemplate: string,
  expectedPattern: any = P.any,
) {
  const isPattern = isMatching(expectedPattern)

  return async (urlParams?: UrlParams): Promise<Result<T>> => {
    const url = urlParams ? renderUrl(urlTemplate, urlParams) : urlTemplate
    try {
      const dto = await apiDelete<T>(url)
      if (isPattern(dto)) return success(dto)
      else {
        console.error(url, 'returned unexpected format', { returned: dto })
        return failure(new ValidationError('Unexpected API response', dto))
      }
    } catch (err) {
      return failure(err as AxiosError)
    }
  }
}

/**
 * @deprecated - use @util/adapter#fetchToCacheRecursive instead, if possible
 */
export async function fetchAllRecords({
  apiFn,
  apiOptions,
  totalPages,
}: {
  apiFn: any
  apiOptions: any
  totalPages: number
}): Promise<any[]> {
  const recordsPromises = []

  for (let page = 2; page <= totalPages; page++) {
    recordsPromises.push(apiFn({ ...apiOptions, page }))
  }
  const recordsResults = await Promise.all(recordsPromises)
  return recordsResults.flatMap((r) => r.results)
}
