<script setup lang="ts">import { ref as _ref } from 'vue';

import { FormKitIcon } from '@formkit/vue'
import { onClickOutside } from '@vueuse/core'
// import TextField from '@component/forms/TextField.vue'

withDefaults(defineProps<{
  reference?: string
  linkData: LinkData[]
  align?: 'left' | 'middle' | 'right'
  vAlign?: 'top' | 'bottom'
}>(), { reference: 'main',linkData: () => ([]),align: 'middle',vAlign: 'bottom', })

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'accept', value: string): void
}>()

let showChildBuilder: number | false = _ref(false)
const childLinkMenu = _ref<HTMLDivElement | null>(null)

const handleSelect = (value: string) => {
  emit('accept', value)
  showChildBuilder.value = false
}

onClickOutside((childLinkMenu), () => {
  showChildBuilder.value = false
})
</script>

<template>
  <div
    class="absolute bg-black dark:bg-white rounded-lg divide-y divide-background dark:divide-gray-dark z-50"
    :class="{
      'top-10': reference !== 'children' && vAlign !== 'top',
      'right-0': align === 'right',
      'left-10': align === 'left',
      'bottom-[100%]': vAlign === 'top',
    }"
  >
    <!-- <TextField placeholder="Search"  search class="w-60" /> -->
    <div
      class="relative flex gap-2 items-center justify-between py-2 px-2 text-white dark:text-black text-base hover:bg-black-soft dark:hover:bg-background cursor-pointer first:rounded-t-lg last:rounded-b-lg"
      :class="{ 'bg-gray-dark dark:bg-background': showChildBuilder === i }"
      v-for="(data, i) in linkData"
      :key="i"
    >
      <div class="flex items-center w-full" @click.stop="handleSelect(data.value)">
        <FormKitIcon v-if="data.icon" class="w-6 h-6 inline mx-2" :icon="data.icon" />
        <span :class="{ 'mx-2': reference === 'children' }">{{ data.label }}</span>
      </div>
      <FormKitIcon
        v-if="data.children?.length"
        class="inline ml-2 rounded-md -rotate-90 cursor-pointer w-4 h-4"
        icon="arrowDown"
        @click="showChildBuilder = i"
      />
      <div
        v-if="data.children?.length"
        ref="childLinkMenu"
        :class="`absolute left-full ml-1 ${data.children?.length >= 2 ? '-top-12' : 'top-0'}`"
      >
        <LinkBuilder
          v-if="showChildBuilder === i"
          :link-data="data.children"
          reference="children"
          @accept="handleSelect($event)"
        />
      </div>
    </div>
  </div>
</template>
