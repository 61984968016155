import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@store/user'
import { useDatasetStore } from '@/stores/dataset'

/**
 * Load permissions allowed for user based on scope ID and user details
 */
export default function usePermissions() {
  const { user } = storeToRefs(useUserStore())
  const { current: currentDataset } = storeToRefs(useDatasetStore())

  const isAllowedToCreateEntity = computed(() => {
    if (user.value.is_reporting_entity_admin || user.value.is_staff) return true
    return false
  })
  const isEsgScoped = computed(() => {
    if (currentDataset.value?.reporting_scope_id === 'esg') return true
    return false
  })
  const isReportingEntityAdmin = computed(() => {
    return user.value.is_reporting_entity_admin
  })
  const isStaffUser = computed(() => {
    return user.value.is_staff
  })

  const isAllowedList = computed(() => {
    const permissions: { parent: string[]; children: string[] } = {
      parent: [],
      children: [],
    }
    if (user.value.permissions) {
      user.value.permissions.forEach((permission) => {
        if (permission.string.indexOf('module:') > -1) {
          const splitVal = permission.string.split(':')
          if (splitVal.length === 3 && permission.string.indexOf('/') === -1) {
            permissions.parent.push(splitVal[1])
          } else if (splitVal.length === 3 && permission.string.indexOf('/') > -1) {
            const childSplit = splitVal[1].split('/')
            permissions.children.push(childSplit[-1])
          }
        }
      })
    }
    // This adds the tasks, materiality and survey permissions to the parent list
    // TODO: this needs to be removed when we want to show Tasks just for clients with the permission!
    permissions.parent.push('tasks')
    permissions.parent.push('survey')
    permissions.parent.push('export')
    //    permissions.parent.push('eu-taxonomy')

    return permissions
  })

  return {
    isAllowedToCreateEntity,
    isEsgScoped,
    isReportingEntityAdmin,
    isStaffUser,
    isAllowedList,
  }
}
