export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-([1-5])[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
export const numberRegex = /^\d+(,\d+)*(\.\d+)?$/

export function extractNumber(value: any) {
  // Regular expression to match numbers with commas and decimals
  const current = `${value}`.trim()

  // Check if the value matches the regular expression
  if (numberRegex.test(current)) {
    // Replace commas with empty strings to convert the string to a number
    const numberString = current.replace(/,/g, '')
    return parseFloat(numberString)
  }

  return value
}

export function validateString(value: any): value is string {
  return typeof value === 'string'
}

export function validateNumber(value: any): value is number {
  return typeof value === 'number' && !isNaN(value)
}

export function validateBoolean(value: any): value is boolean {
  return typeof value === 'boolean'
}

export function validatePrimitive(value: any): value is Primitive {
  return validateString(value) || validateNumber(value) || validateBoolean(value)
}

export function validateArray(value: any): value is any[] {
  return Array.isArray(value)
}

export function validateDate(value: any): value is Date {
  const date = new Date(value)
  return date instanceof Date && !isNaN(date.getTime())
}

export function validateEmail(email: any): email is Email {
  if (!validateString(email)) return false
  return emailRegex.test(email)
}

export type UUIDVersion = 1 | 2 | 3 | 4 | 5
export function getUUIDVersion(value: string): 0 | UUIDVersion {
  if (typeof value !== 'string') return 0
  const result = value.match(uuidRegex)
  return result === null ? 0 : (parseInt(result[1]) as UUIDVersion)
}

export function validateUUID(value: string): value is UUID {
  const uuidVersion = getUUIDVersion(value)
  return uuidVersion !== 0
}
