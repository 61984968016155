import { apiFetch, apiUpdate, apiCreate, apiDelete, apiOptions, authlessAxios, defaultPageSize } from '@api/client'
import type { UploadStatus } from '@api/upload'
export type { UploadStatus } from '@api/upload'

import type { Dataset, DatasetDetails } from '@store/dataset'
import type { ReportingEntity, ReportingEntityOptions, ReportingEntityPostObj } from '@store/reportingEntities'
import type { OptimizationGoal } from '@store/optimization-goal'
import type { OptimizationMeasure } from '@store/measure'
import { isGRIScope, type GRIData, type GRIScope, type GRIParams } from '@/util/gri'
import type { Report, Draft } from '@store/reporting/reports'
import type { ReportingStandard } from '@store/reporting/standards'
import type { ContextualHelpData } from '@store/contextual-help'
import type { BenchmarkItem, BenchMarkOptions, FileSpecificData, FileSpecificPdfData } from '@store/gri'

import { objectToFormData } from '@/util'
import type { GeneratedExportsItem } from '@store/reporting/exports'
import type { TaskCreateObj, TaskItem, Checklist } from '@store/tasks'

import type { MaterialityTopic } from '@store/reporting/materialityTopics'
import type { MaterialityIros } from '@store/reporting/materialityImpacts'

export async function fetchGriData(
  subSet: GRIScope,
  params: GRIParams,
  dashboard?: boolean, // make dashboard data load faster
): Promise<GRIData> {
  if (!isGRIScope(subSet)) return Promise.reject()

  // gri-123-4 => 123/4 or 123/4dashboard
  const url = `gri/${subSet.slice(4).replace('-', '/')}${dashboard ? 'dashboard' : ''}`

  if (params.version === '3') {
    return apiCreate<GRIData>(import.meta.env.VITE_BASE_V3_URL + url, {
      reference_reporting_entity_id: params.referenceReportingEntityId,
      dataset_ids: params.datasetIds,
      start_date: params.startDate,
      end_date: params.endDate,
    })
  }

  return apiFetch<GRIData>(
    url,
    {
      dataset: params.datasetId,
      start_date: params.startDate,
      end_date: params.endDate,
    },
    2,
  )
}

// Added new to fetch Benchmark Options
export async function fetchBenchmarkOptions(): Promise<BenchMarkOptions> {
  const roleResponse = await apiOptions<BenchMarkOptions>(`benchmarks/dataset-sectors`)
  return roleResponse.data
}

//fetchBenchmark of a dataset
export async function getBenchMarkOfDataset(datasetId: string): Promise<BenchmarkItem> {
  const roleResponse = await apiFetch<BenchmarkItem>(`benchmarks/dataset-sectors/${datasetId}`)
  return roleResponse
}

export async function updateBenchmarkSector(datasetId: string, sector: number | null): Promise<BenchmarkItem> {
  const roleResponse = await apiUpdate<BenchmarkItem>(`benchmarks/dataset-sectors/${datasetId}`, { sector: sector })
  return roleResponse
}

export async function fetchDataset(id: string): Promise<Dataset> {
  const url = `/datasets/${id}`
  return await apiFetch<Dataset>(url)
}

// fetch goals and measures for improvement page
export async function getGoals() {
  const response = await apiFetch<OptimizationGoal[]>('goals/')
  return response
}

export async function getMeasures() {
  const response = await apiFetch<OptimizationMeasure[]>('measures/')
  return response
}

// get all datasets irrespective of selected reporting entity
export async function getAllDatasets(
  page: number,
  pageSize: number = defaultPageSize,
  staff: boolean = false,
): Promise<Paginated<DatasetDetails>> {
  const url = 'datasets'
  const params: Record<string, any> = { all: staff, page, page_size: pageSize }
  const response = await apiFetch<Paginated<DatasetDetails>>(url, params)
  return response
}

export async function createDataset(dataset: Dataset): Promise<Dataset> {
  const url = `/datasets`

  const partialDataset: Partial<Dataset> = { ...dataset }
  delete partialDataset['closed']

  return await apiCreate<Dataset>(url, partialDataset)
}

export async function updateDataset(dataset: Partial<Dataset>): Promise<Dataset> {
  const url = `/datasets/${dataset.id}`
  return await apiUpdate<Dataset>(url, dataset)
}

export async function deleteDataset(datasetId: string): Promise<void> {
  const url = `/datasets/${datasetId}`
  await apiDelete<Dataset>(url)
}

//todo: types for organization data is needed to be added yet.
export async function createOrganizationData(datasetId: string, entityName: string) {
  const url = `data/organization-data`
  return await apiCreate(url, {
    dataset_id: datasetId,
    name: entityName,
  })
}

//ReportingEntity related CRUD operations
export async function getEntityRoles(): Promise<ReportingEntityOptions> {
  const roleResponse = await apiOptions<ReportingEntityOptions>(`reporting-entities`)
  return roleResponse.data
}

export async function fetchReportingEntity(
  pageSize: number = defaultPageSize,
  isStaffUser: boolean = false,
): Promise<ReportingEntity[]> {
  const url = `reporting-entities`
  let entities: ReportingEntity[] = []
  let response = await apiFetch<Paginated<ReportingEntity>>(url, { all: isStaffUser, page_size: pageSize })
  entities = response.results
  if (response.count > pageSize) {
    for (let i = 2; i <= Math.ceil(response.count / pageSize); i++) {
      response = await apiFetch<Paginated<ReportingEntity>>(url, {
        all: isStaffUser,
        page: `${i}`,
        page_size: pageSize,
      })
      entities = [...entities, ...response.results]
    }
  }
  return entities
}

export async function createReportingEntity(entity: ReportingEntityPostObj): Promise<ReportingEntity> {
  const url = `/reporting-entities`
  return await apiCreate<ReportingEntity>(url, entity)
}

export async function getContextualHelp(reference: string): Promise<ContextualHelpData> {
  const url = 'content/question-help'
  const response = await apiFetch<Paginated<ContextualHelpData>>(url, {
    reference,
  })
  return response.results[0]
}

export async function getActivitySpecificFileDetails(
  type: string,
  fileID: string,
): Promise<FileSpecificData | FileSpecificPdfData> {
  const url = type === 'excel' ? `imports/${fileID}` : `invoice-pages?hash=${fileID}&expand=original_upload`
  const version = type === 'excel' ? 1 : 2
  if (type === 'excel') {
    const response = await apiFetch<FileSpecificData>(url, undefined, version)
    return response
  } else {
    const response = await apiFetch<Paginated<FileSpecificPdfData>>(url, undefined, version)
    return response.results && response.results.length > 0 ? response.results[0] : ({} as FileSpecificPdfData)
  }
}

export async function fetchReportingStandards(): Promise<Paginated<ReportingStandard>> {
  const url = 'reporting-standards'
  return await apiFetch<Paginated<ReportingStandard>>(url)
}

export async function handleS3UploadOfDisclosures(payload: any, file: FilePayload) {
  const url = 'disclosure-attachments'
  let response = await apiCreate<any>(url, payload, 2)
  const attachment = {
    file_name: payload.file_name,
    upload_status: 'WAITING',
    disclosure_attachment_id: response.id,
  }
  const s3payload = objectToFormData(response.file_post_info.fields)
  s3payload.append('file', file.file)
  response = await authlessAxios({
    method: 'post',
    url: response.file_post_info.url,
    headers: { 'Content-Type': 'multipart/form-data' },
    data: s3payload,
  })
  return attachment
}

export type DisclosureAttachment = {
  id: string
  hierarchy_id: string
  reporting_entity_id: string
  reporting_requirement_id: number
  report_id: string
  file: string
  file_name: string
  file_post_info: {
    url: string
    fields: {
      key: string
      'x-amz-algorithm': string
      'x-amz-credential': string
      'x-amz-date': string
      policy: string
      'x-amz-signature': string
    }
  }
  hash: string
  upload_status: UploadStatus
  created_at: string
  modified_at: string
  user_id: string | null
  user_email: string | null
}

export async function getDisclosureAttachment(attachmentId: string) {
  const url = `disclosure-attachments/${attachmentId}`
  const response = await apiFetch<DisclosureAttachment>(url, undefined, 2)
  return response
}

export async function deleteDisclosureAttachment(attachmentId: string) {
  const url = `disclosure-attachments/${attachmentId}`
  const response = await apiDelete(url, 2)
  return response
}

/*** Exports of requirement Standards ***/
export type REPORT_EXPORT_STATUS = 'WAITING' | 'COMPLETED' | 'FAILED'

export async function fetchGeneratedExports(
  reportId: string,
  standardId: number | undefined,
): Promise<GeneratedExportsItem[]> {
  const url = `/reports/${reportId}/reporting-standards/${standardId}/exports`
  const response = await apiFetch<Paginated<GeneratedExportsItem>>(url)
  return response.results
}

export async function createGeneratedExports(reportId: string, standardId: number | undefined) {
  const url = `/reports/${reportId}/reporting-standards/${standardId}/exports`
  return await apiCreate<Report>(url, {})
}

export async function pollGeneratedExports(id: string): Promise<GeneratedExportsItem> {
  const url = `/report-exports/${id}`
  const response = await apiFetch<GeneratedExportsItem>(url)
  return response
}

export async function deleteGeneratedExports(id: string) {
  const url = `/report-exports/${id}`
  await apiDelete(url)
}
export type ActivityType = {
  id: string
  keywords: string[]
  name: string
}

/*** Tasks related API endpoints ***/
export async function getTasks(
  entityId: string,
  page = 1,
  pageSize = defaultPageSize,
  all = false,
): Promise<Paginated<TaskItem>> {
  const url = '/tasks'
  const params: Record<string, any> = {
    reporting_entity_id: entityId,
    page: page,
    page_size: pageSize,
    expand: 'assignee',
  }

  if (!entityId) throw new Error('Reporting Entity ID must not be falsy!')

  const paginatedTasks = {} as Paginated<TaskItem>
  let response = await apiFetch<Paginated<TaskItem>>(url, params)
  paginatedTasks.results = response.results
  paginatedTasks.count = response.count
  if (response.count > pageSize && all) {
    for (let i = 2; i <= Math.ceil(response.count / pageSize); i++) {
      params.page = `${i}`
      response = await apiFetch<Paginated<TaskItem>>(url, params)
      paginatedTasks.results = [...paginatedTasks.results, ...response.results]
    }
  }
  return paginatedTasks
}

export async function getTask(taskId: string, entityId?: string): Promise<TaskItem> {
  const url = `/tasks/${taskId}`
  const params: Record<string, any> = {}
  if (entityId) params.reporting_entity_id = entityId
  return await apiFetch<TaskItem>(url, params)
}

export async function createTasks(taskObj: TaskCreateObj) {
  const url = '/tasks'
  return await apiCreate<TaskItem>(url, taskObj)
}

export async function editTask(taskObj: Partial<TaskCreateObj>, taskId: string) {
  const url = `/tasks/${taskId}`
  return await apiUpdate<TaskItem>(url, taskObj)
}

export async function editChecklistTask(checklist: Checklist[], taskId: UUID) {
  const url = `/tasks/${taskId}`
  return await apiUpdate<TaskItem>(url, { checklist })
}

export async function deleteTask(entityId: string, taskId: string) {
  const url = `tasks/${taskId}?reporting_entity_id=${entityId}`
  return await apiDelete<TaskCreateObj>(url)
}

// Materiality Topics related API endpoints
export async function getMaterialityTopics({
  onlyParents = false,
  page = 1,
  perPage = 100,
  parentId,
}: { onlyParents?: boolean; parentId?: string; page?: number; perPage?: number } = {}): Promise<
  Paginated<MaterialityTopic>
> {
  const url = `materiality/topics`
  const params: Record<string, any> = {
    page,
    page_size: perPage,
  }

  if (parentId) {
    params.parent = parentId
  }

  if (onlyParents) {
    params.parent__isnull = true
  }
  return await apiFetch(url, params)
}

// Materiality IROS (Impacts, Risks, Opportunities) related API endpoints

export async function getMaterialityIros({
  processId,
  topicId,
  typeIn,
  type,
  searchQuery,
  includeMerged,
  page = 1,
}: {
  processId?: string
  topicId?: string
  type?: string
  typeIn?: string
  searchQuery?: string
  includeMerged?: boolean
  page?: number
} = {}): Promise<Paginated<MaterialityIros>> {
  const url = `materiality/iros`
  const params: Record<string, any> = {
    page,
    materiality_process_id: processId,
  }

  if (includeMerged) {
    params.include_merged = true
  }

  if (topicId) {
    params.topic_id = topicId
  }

  if (typeIn) {
    params.type__in = typeIn
  }

  if (type) {
    params.type = type
  }
  if (searchQuery) params.text__icontains = searchQuery
  return await apiFetch(url, params)
}

export async function getMaterialityIro(iroId: string): Promise<MaterialityIros> {
  const url = `materiality/iros/${iroId}`
  return await apiFetch(url)
}

export async function createMaterialityIros(irosObject: Partial<MaterialityIros>): Promise<MaterialityIros> {
  const url = `materiality/iros`
  return await apiCreate(url, irosObject)
}

export async function editMaterialityIros(
  iroId: string,
  irosObject: Partial<MaterialityIros>,
): Promise<MaterialityIros> {
  const url = `materiality/iros/${iroId}`
  return await apiUpdate(url, irosObject)
}

export async function deleteMaterialityIros(id: string) {
  const url = `materiality/iros/${id}`
  return await apiDelete(url)
}

export async function assessMaterialityIros(impactId: string, data: MaterialityIros): Promise<MaterialityIros> {
  const url = `materiality/iros/${impactId}/assess`
  return await apiCreate(url, data)
}

export async function mergeMaterialityIros(iroId: string, targetId: string): Promise<MaterialityIros> {
  const url = `materiality/iros/${iroId}/merge`
  return await apiCreate(url, { target_id: targetId })
}

// generate disclosure content with LLM model
export async function generateDisclosureDraft(
  report_id: string,
  draftObject: Pick<Draft, 'reporting_requirement_id' | 'language' | 'user_input'>,
) {
  const url = `reports/${report_id}/drafts`
  return await apiCreate<Draft>(url, draftObject)
}

export async function fetchDisclosureDraft(reportId: string, draftId: string) {
  const url = `reports/${reportId}/drafts/${draftId}`
  return await apiFetch<Draft>(url)
}

export async function updateDisclosureDraft(
  reportId: string,
  draftId: string,
  draftUpdateObject: Pick<Draft, 'user_response'>,
) {
  const url = `reports/${reportId}/drafts/${draftId}`
  return await apiUpdate(url, draftUpdateObject)
}
